body {
  font-family: 'Avenir Heavy';

  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

#root {
  display: flex;
  justify-content: center;
}

.filepicker-file-icon {
  position: relative;

  display: inline-block;

  margin: 1.5em 0 2.5em 0;
  padding-left: 45px;

  color: black;
}
.filepicker-file-icon::before {
  position: absolute;
  top: -7px;
  left: 0;

  width: 29px;
  height: 34px;

  content: '';

  border: solid 2px #7f7f7f;
  border-radius: 2px;
}
.filepicker-file-icon::after {
  font-size: 11px;
  line-height: 1.3;

  position: absolute;
  top: 9px;
  left: -4px;

  padding: 0 2px;

  content: 'file';
  content: attr(data-filetype);
  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #fff;
  background-color: #000;
}

.filepicker-file-icon .fileCorner {
  position: absolute;
  top: -7px;
  left: 22px;

  width: 0;
  height: 0;

  border-width: 11px 0 0 11px;
  border-style: solid;
  border-color: white transparent transparent #920035;
}

a.no-underline:hover,
a.no-underline:visited,
a.no-underline:link,
a.no-underline:active {
  text-decoration: none;
}

a.brand:hover,
a.brand:visited,
a.brand:link,
a.brand:active {
  color: #f08730;
}

/* Full Calendar customization */
.fc-button {
  background-color: #fac446 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border: none !important;
  font-size: 0.8125rem !important;
  margin-top: 4px !important;
}

.fc-button:hover {
  background-color: rgb(230, 180, 64) !important;
}

.fc-toolbar-title {
  font-size: 18px !important;
}
